// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "stripeValidations",
    "operatorDashboardFilters",
    "operatorReports",
    "driverTour",
    "utilizationListFilters",
    "roleModules",
    "roleActions",
  ], // This array will include the strings of all those reducers that we want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export { store };
export const persistor = persistStore(store);
