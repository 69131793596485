import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";

const initialState = {
  operator_dashboard_filters: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: [],
  },
  operator_dashboard_filters_revenue: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: [],
  },
  operator_dashboard_filters_utilization: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: [],
  },
  operator_dashboard_filters_visitTime: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: [],
  },
  operator_dashboard_filters_drivers: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: [],
  },
  operator_dashboard_filters_variableRatePerformance: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: [],
  },
  operator_dashboard_results_loading: {
    is_global_loading: false,
    is_revenue_loading: false,
    is_utilization_loading: false,
    is_visitTime_loading: false,
    is_drivers_loading: false,
    is_variableRatePerformance_loading: false,
  },
  operator_dashboard_results: null,
  operator_dashboard_compare_results: null,
  operator_dashboard_results_revenue: null,
  operator_dashboard_compare_results_revenue: null,
  operator_dashboard_results_utilization: null,
  operator_dashboard_compare_results_utilization: null,
  operator_dashboard_results_visitTime: null,
  operator_dashboard_compare_results_visitTime: null,
  operator_dashboard_results_drivers: null,
  operator_dashboard_compare_results_drivers: null,
  operator_dashboard_results_variableRatePerformance: null,
  operator_dashboard_compare_results_variableRatePerformance: null,
};

const operatorDashboardFiltersSlice = createSlice({
  name: "operatorDashboardFilters",
  initialState,
  reducers: {
    setOperatorDashboardFilters: (state, action) => {
      state.operator_dashboard_filters = action.payload;
    },
    setOperatorDashboardFiltersRevenue: (state, action) => {
      state.operator_dashboard_filters_revenue = action.payload;
    },
    setOperatorDashboardFiltersUtilization: (state, action) => {
      state.operator_dashboard_filters_utilization = action.payload;
    },
    setOperatorDashboardFiltersVisitTime: (state, action) => {
      state.operator_dashboard_filters_visitTime = action.payload;
    },
    setOperatorDashboardFiltersDrivers: (state, action) => {
      state.operator_dashboard_filters_drivers = action.payload;
    },
    setOperatorDashboardFiltersVariableRatePerformance: (state, action) => {
      state.operator_dashboard_filters_variableRatePerformance = action.payload;
    },
    setOperatorDashboardResultsLoading: (state, action) => {
      state.operator_dashboard_results_loading = action.payload;
    },
    setOperatorDashboardResults: (state, action) => {
      state.operator_dashboard_results = action.payload;
    },
    setOperatorDashboardCompareResults: (state, action) => {
      state.operator_dashboard_compare_results = action.payload;
    },
    setOperatorDashboardResultsRevenue: (state, action) => {
      state.operator_dashboard_results_revenue = action.payload;
    },
    setOperatorDashboardCompareResultsRevenue: (state, action) => {
      state.operator_dashboard_compare_results_revenue = action.payload;
    },
    setOperatorDashboardResultsUtilization: (state, action) => {
      state.operator_dashboard_results_utilization = action.payload;
    },
    setOperatorDashboardCompareResultsUtilization: (state, action) => {
      state.operator_dashboard_compare_results_utilization = action.payload;
    },
    setOperatorDashboardResultsVisitTime: (state, action) => {
      state.operator_dashboard_results_visitTime = action.payload;
    },
    setOperatorDashboardCompareResultsVisitTime: (state, action) => {
      state.operator_dashboard_compare_results_visitTime = action.payload;
    },
    setOperatorDashboardResultsDrivers: (state, action) => {
      state.operator_dashboard_results_drivers = action.payload;
    },
    setOperatorDashboardCompareResultsDrivers: (state, action) => {
      state.operator_dashboard_compare_results_drivers = action.payload;
    },
    setOperatorDashboardResultsVariableRatePerformance: (state, action) => {
      state.operator_dashboard_results_variableRatePerformance = action.payload;
    },
    setOperatorDashboardCompareResultsVariableRatePerformance: (
      state,
      action
    ) => {
      state.operator_dashboard_compare_results_variableRatePerformance =
        action.payload;
    },
    setOperatorDashboardFiltersToInitialState: (state) => {
      (state.operator_dashboard_filters =
        initialState.operator_dashboard_filters),
        (state.operator_dashboard_results_loading =
          initialState.operator_dashboard_results_loading),
        (state.operator_dashboard_results =
          initialState.operator_dashboard_results),
        (state.operator_dashboard_compare_results =
          initialState.operator_dashboard_compare_results),
        (state.operator_dashboard_filters_revenue =
          initialState.operator_dashboard_filters_revenue),
        (state.operator_dashboard_results_revenue =
          initialState.operator_dashboard_results_revenue),
        (state.operator_dashboard_compare_results_revenue =
          initialState.operator_dashboard_compare_results_revenue),
        (state.operator_dashboard_filters_utilization =
          initialState.operator_dashboard_filters_utilization),
        (state.operator_dashboard_results_utilization =
          initialState.operator_dashboard_results_utilization),
        (state.operator_dashboard_compare_results_utilization =
          initialState.operator_dashboard_compare_results_utilization),
        (state.operator_dashboard_filters_visitTime =
          initialState.operator_dashboard_filters_visitTime),
        (state.operator_dashboard_results_visitTime =
          initialState.operator_dashboard_results_visitTime),
        (state.operator_dashboard_compare_results_visitTime =
          initialState.operator_dashboard_compare_results_visitTime),
        (state.operator_dashboard_filters_drivers =
          initialState.operator_dashboard_filters_drivers),
        (state.operator_dashboard_results_drivers =
          initialState.operator_dashboard_results_drivers),
        (state.operator_dashboard_compare_results_drivers =
          initialState.operator_dashboard_compare_results_drivers),
        (state.operator_dashboard_filters_variableRatePerformance =
          initialState.operator_dashboard_filters_variableRatePerformance),
        (state.operator_dashboard_results_variableRatePerformance =
          initialState.operator_dashboard_results_variableRatePerformance),
        (state.operator_dashboard_compare_results_variableRatePerformance =
          initialState.operator_dashboard_compare_results_variableRatePerformance);
    },
    setOperatorDashboardFiltersRevenueToInitialState: (state) => {
      (state.operator_dashboard_results_revenue =
        initialState.operator_dashboard_results_revenue),
        (state.operator_dashboard_compare_results_revenue =
          initialState.operator_dashboard_compare_results_revenue);
    },
    setOperatorDashboardFiltersUtilizationToInitialState: (state) => {
      (state.operator_dashboard_results_utilization =
        initialState.operator_dashboard_results_utilization),
        (state.operator_dashboard_compare_results_utilization =
          initialState.operator_dashboard_compare_results_utilization);
    },
    setOperatorDashboardFiltersVisitTimeToInitialState: (state) => {
      (state.operator_dashboard_results_visitTime =
        initialState.operator_dashboard_results_visitTime),
        (state.operator_dashboard_compare_results_visitTime =
          initialState.operator_dashboard_compare_results_visitTime);
    },
    setOperatorDashboardFiltersDriversToInitialState: (state) => {
      (state.operator_dashboard_results_drivers =
        initialState.operator_dashboard_results_drivers),
        (state.operator_dashboard_compare_results_drivers =
          initialState.operator_dashboard_compare_results_drivers);
    },
    setOperatorDashboardFiltersVariableRatePerformanceToInitialState: (
      state
    ) => {
      (state.operator_dashboard_results_variableRatePerformance =
        initialState.operator_dashboard_results_variableRatePerformance),
        (state.operator_dashboard_compare_results_variableRatePerformance =
          initialState.operator_dashboard_compare_results_variableRatePerformance);
    },
  },
});

export default operatorDashboardFiltersSlice.reducer;
export const {
  setOperatorDashboardFilters,
  setOperatorDashboardResultsLoading,
  setOperatorDashboardResults,
  setOperatorDashboardCompareResults,
  setOperatorDashboardFiltersToInitialState,
  setOperatorDashboardFiltersRevenue,
  setOperatorDashboardFiltersRevenueToInitialState,
  setOperatorDashboardResultsRevenue,
  setOperatorDashboardCompareResultsRevenue,
  setOperatorDashboardFiltersUtilization,
  setOperatorDashboardResultsUtilization,
  setOperatorDashboardCompareResultsUtilization,
  setOperatorDashboardFiltersUtilizationToInitialState,
  setOperatorDashboardFiltersVisitTime,
  setOperatorDashboardResultsVisitTime,
  setOperatorDashboardCompareResultsVisitTime,
  setOperatorDashboardFiltersVisitTimeToInitialState,
  setOperatorDashboardFiltersDrivers,
  setOperatorDashboardResultsDrivers,
  setOperatorDashboardCompareResultsDrivers,
  setOperatorDashboardFiltersDriversToInitialState,
  setOperatorDashboardFiltersVariableRatePerformance,
  setOperatorDashboardResultsVariableRatePerformance,
  setOperatorDashboardCompareResultsVariableRatePerformance,
  setOperatorDashboardFiltersVariableRatePerformanceToInitialState,
} = operatorDashboardFiltersSlice.actions;
