import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  order_fob: {
    totalPrice: 0,
    shippingCharges: 0,
    couponCode: "",
    discountAmount: 0,
    isCouponCodeApplied: false,
    isNextDayShipping: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isOrderForSelf: false,
    paymentSourceType: 0,
    paymentMethodID: "",
    orderItems: [
      {
        quantity: 0,
        unitPrice: 10,
        fk_ProductID: 1,
      },
    ],
    shippingAddress: {
      id: 0,
      firstName: "",
      lastName: "",
      street: "",
      city: "",
      zipCode: "",
      country: "",
      state: "",
      address: "",
    },
    isSummaryTab: false,
  },
};

const orderFobSlice = createSlice({
  name: "orderFob",
  initialState,
  reducers: {
    setOrderFob: (state, action) => {
      state.order_fob = action.payload;
    },
    setOrderFobToInitialState: (state, action) => {
      state.order_fob = initialState.order_fob;
    },
  },
});

export default orderFobSlice.reducer;
export const { setOrderFob, setOrderFobToInitialState } = orderFobSlice.actions;
