import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  utilization_list_filters: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    operators: null,
    operatorsList: [],
    startDateForQuery: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDateForQuery: moment().format("YYYY-MM-DD"),
    locationsForQuery: [],
    lotsForQuery: [],
    sectionsForQuery: [],
    operatorsForQuery: null,
  },
};

const utilizationListFiltersSlice = createSlice({
  name: "utilizationListFilters",
  initialState,
  reducers: {
    setUtilizationListFilters: (state, action) => {
      state.utilization_list_filters = action.payload;
    },
    setUtilizationListFiltersToInitialState: (state, action) => {
      state.utilization_list_filters = initialState.utilization_list_filters;
    },
  },
});

export default utilizationListFiltersSlice.reducer;
export const {
  setUtilizationListFilters,
  setUtilizationListFiltersToInitialState,
} = utilizationListFiltersSlice.actions;
