import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";

const initialState = {
  parking_history_filters: {
    showDefaultDateRange: true,
    defaultStartDate: null,
    defaultEndDate: null,
    dateRange: null,
    startDate: null,
    endDate: null,
    startDateToSend: null,
    endDateToSend: null,
  }
};

const parkingHistoryFiltersSlice = createSlice({
  name: "parkingHistoryFilters",
  initialState,
  reducers: {
    setParkingHistoryFilters: (state, action) => {
      state.parking_history_filters = action.payload;
    },
    setParkingHistoryFiltersToInitialState: (state) => {
      (state.parking_history_filters =
        initialState.parking_history_filters)
    }
  },
});

export default parkingHistoryFiltersSlice.reducer;
export const {
  setParkingHistoryFilters,
  setParkingHistoryFiltersToInitialState
} = parkingHistoryFiltersSlice.actions;
