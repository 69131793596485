import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operators_forms_responses: [],
};

const operatorsFormsResponsesSlice = createSlice({
  name: "operatorsFormsResponses",
  initialState,
  reducers: {
    addOperatorsFormsResponsesInList: (state, action) => {
      state.operators_forms_responses = [
        ...state.operators_forms_responses,
        action.payload,
      ];
    },
    removeOperatorsFormsResponseFromList: (state, action) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.filter((so) => {
          return (
            so?.driversPreferrence.fk_OperatorId != action.payload.operatorId
          );
        });
      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
    setOperatorsFormsResponses: (state, action) => {
      const allFormsResponses = [
        ...state.operators_forms_responses,
        ...action.payload,
      ];
      const filteredFormsResponses = allFormsResponses.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) =>
              v2.driversPreferrence.fk_OperatorId ==
              v.driversPreferrence.fk_OperatorId
          ) == i
      );
      state.operators_forms_responses = filteredFormsResponses;
    },
    emptyOperatorsFormsResponsesList: (state, action) => {
      state.operators_forms_responses = [];
    },
    toggleIsSelectedInOperatorsFormsResponses: (state, action) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.map((so) => {
          return so?.driversPreferrence.fk_OperatorId ==
            action.payload.fk_ParkingOperatorID
            ? {
                ...so,
                isSelected: !so.isSelected,
              }
            : so;
        });

      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
    setPermitChangesInOperatorsFormsResponses: (state, action) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.map((so) => {
          return so?.driversPreferrence.fk_OperatorId ==
            action.payload.fk_ParkingOperatorID
            ? {
                ...so,
                havePermit:
                  "havePermit" in action.payload
                    ? action.payload.havePermit
                    : so.havePermit,
                isPermittedToProceed:
                  "isPermittedToProceed" in action.payload
                    ? action.payload.isPermittedToProceed
                    : so.isPermittedToProceed,
                driversPreferrence: {
                  ...so.driversPreferrence,
                  fk_PermitID:
                    "fk_PermitID" in action.payload
                      ? action.payload.fk_PermitID
                      : so.driversPreferrence.fk_PermitID,
                },
              }
            : so;
        });

      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
    setLocationIdInOperatorsFormsResponses: (state, action) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.map((so) => {
          return so?.driversPreferrence.fk_OperatorId ==
            action.payload.fk_ParkingOperatorID
            ? {
                ...so,
                driversPreferrence: {
                  ...so.driversPreferrence,
                  fk_PreferredLocationId: action.payload.locationId
                    ? action.payload.locationId
                    : 0,
                },
                location: action.payload.location,
              }
            : so;
        });

      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
    addQuestionResponseInOperatorsFormsResponses: (state, action) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.map((so) => {
          return so?.driversPreferrence.fk_OperatorId ==
            action.payload.operatorId
            ? {
                ...so,
                driversQuestionSubmissions: [
                  ...so?.driversQuestionSubmissions,
                ]?.find(
                  (dqs) =>
                    dqs?.fk_QuestionId ==
                    action.payload.questionResponse.fk_QuestionId
                )
                  ? [...so.driversQuestionSubmissions]?.map((dqs) => {
                      return dqs?.fk_QuestionId ==
                        action.payload.questionResponse.fk_QuestionId
                        ? {
                            ...dqs,
                            fk_QuestionItemId:
                              action.payload.questionResponse.fk_QuestionItemId,
                          }
                        : dqs;
                    })
                  : [
                      ...so?.driversQuestionSubmissions,
                      action.payload.questionResponse,
                    ],
              }
            : so;
        });

      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
    removeQuestionResponseFromOperatorsFormsResponses: (state, action) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.map((so) => {
          return so?.driversPreferrence.fk_OperatorId ==
            action.payload.operatorId
            ? {
                ...so,
                driversQuestionSubmissions: [
                  ...so.driversQuestionSubmissions,
                ].filter((dqs) => {
                  return (
                    dqs?.fk_QuestionId !=
                    action.payload.questionResponse.fk_QuestionId
                  );
                }),
              }
            : so;
        });

      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
    addQuestionResponseInOperatorsFormsResponsesCheckboxCase: (
      state,
      action
    ) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.map((so) => {
          return so?.driversPreferrence.fk_OperatorId ==
            action.payload.operatorId
            ? {
                ...so,
                driversQuestionSubmissions: [
                  ...so.driversQuestionSubmissions,
                  action.payload.questionResponse,
                ],
              }
            : so;
        });

      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
    removeQuestionResponseFromOperatorsFormsResponsesCheckboxCase: (
      state,
      action
    ) => {
      const filteredOperatorsFormsResponses =
        state.operators_forms_responses.map((so) => {
          return so?.driversPreferrence.fk_OperatorId ==
            action.payload.operatorId
            ? {
                ...so,
                driversQuestionSubmissions: [
                  ...so.driversQuestionSubmissions,
                ].filter((dqs) => {
                  return (
                    dqs?.fk_QuestionItemId !=
                    action.payload.questionResponse.fk_QuestionItemId
                  );
                }),
              }
            : so;
        });

      state.operators_forms_responses = filteredOperatorsFormsResponses;
    },
  },
});

export default operatorsFormsResponsesSlice.reducer;
export const {
  addOperatorsFormsResponsesInList,
  removeOperatorsFormsResponseFromList,
  setOperatorsFormsResponses,
  emptyOperatorsFormsResponsesList,
  toggleIsSelectedInOperatorsFormsResponses,
  setPermitChangesInOperatorsFormsResponses,
  setLocationIdInOperatorsFormsResponses,
  addQuestionResponseInOperatorsFormsResponses,
  removeQuestionResponseFromOperatorsFormsResponses,
  addQuestionResponseInOperatorsFormsResponsesCheckboxCase,
  removeQuestionResponseFromOperatorsFormsResponsesCheckboxCase,
} = operatorsFormsResponsesSlice.actions;
