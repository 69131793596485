import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected_preferred_operators: [],
};

const selectedPreferredOperatorsSlice = createSlice({
  name: "selectedPreferredOperators",
  initialState,
  reducers: {
    addPreferredOperatorInList: (state, action) => {
      state.selected_preferred_operators = [
        ...state.selected_preferred_operators,
        action.payload,
      ];
    },
    removePreferredOperatorFromList: (state, action) => {
      const filteredSelectedOperators =
        state.selected_preferred_operators.filter((so) => {
          return (
            so?.fk_ParkingOperatorID != action.payload.fk_ParkingOperatorID
          );
        });
      state.selected_preferred_operators = filteredSelectedOperators;
    },
    emptyPreferredOperatorsList: (state, action) => {
      state.selected_preferred_operators = [];
    },
  },
});

export default selectedPreferredOperatorsSlice.reducer;
export const {
  addPreferredOperatorInList,
  removePreferredOperatorFromList,
  emptyPreferredOperatorsList,
} = selectedPreferredOperatorsSlice.actions;
