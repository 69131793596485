import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role_modules: [],
};

const roleModulesSlice = createSlice({
  name: "roleModules",
  initialState,
  reducers: {
    setRoleModules: (state, action) => {
      state.role_modules = action.payload;
    },
    setRoleModulesToInitialState: (state) => {
      state.role_modules = initialState.role_modules;
    },
  },
});

export default roleModulesSlice.reducer;
export const { setRoleModules, setRoleModulesToInitialState } =
  roleModulesSlice.actions;
