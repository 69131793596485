import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active_sessions_filters: {
    locations: [],
    lots: [],
    sections: []
  }
};

const activeSessionsFiltersSlice = createSlice({
  name: "activeSessionsFilters",
  initialState,
  reducers: {
    setActiveSessionsFilters: (state, action) => {
      state.active_sessions_filters = action.payload;
    },
    setActiveSessionsFiltersToInitialState: (state) => {
      (state.active_sessions_filters =
        initialState.active_sessions_filters);
    }
  },
});

export default activeSessionsFiltersSlice.reducer;
export const {
    setActiveSessionsFilters,
    setActiveSessionsFiltersToInitialState
} = activeSessionsFiltersSlice.actions;
