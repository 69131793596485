import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role_actions: [],
};

const roleActionsSlice = createSlice({
  name: "roleActions",
  initialState,
  reducers: {
    setRoleActions: (state, action) => {
      state.role_actions = action.payload;
    },
    setRoleActionsToInitialState: (state) => {
      state.role_actions = initialState.role_actions;
    },
  },
});

export default roleActionsSlice.reducer;
export const { setRoleActions, setRoleActionsToInitialState } =
  roleActionsSlice.actions;
