import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show_driver_tour: false,
};

const driverTourSlice = createSlice({
  name: "driverTour",
  initialState,
  reducers: {
    setShowDriverTour: (state, action) => {
      state.show_driver_tour = action.payload;
    },
  },
});

export default driverTourSlice.reducer;
export const { setShowDriverTour } = driverTourSlice.actions;
