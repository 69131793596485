import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unread_notifications_count: 0,
};

const unreadNotificationsCountSlice = createSlice({
  name: "unreadNotificationsCount",
  initialState,
  reducers: {
    setUnreadNotificationsCount: (state, action) => {
      state.unread_notifications_count = action.payload;
    },
    incrementUnreadNotificationsCount: (state, action) => {
      state.unread_notifications_count =
        state.unread_notifications_count + action.payload;
    },
    decrementUnreadNotificationsCount: (state, action) => {
      state.unread_notifications_count =
        state.unread_notifications_count - action.payload;
    },
  },
});

export default unreadNotificationsCountSlice.reducer;
export const {
  setUnreadNotificationsCount,
  incrementUnreadNotificationsCount,
  decrementUnreadNotificationsCount,
} = unreadNotificationsCountSlice.actions;
