import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show_stripe_validations: false,
};

const stripeValidationsSlice = createSlice({
  name: "stripeValidations",
  initialState,
  reducers: {
    setShowStripeValidations: (state, action) => {
      state.show_stripe_validations = action.payload;
    },
  },
});

export default stripeValidationsSlice.reducer;
export const { setShowStripeValidations } = stripeValidationsSlice.actions;
