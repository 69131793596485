import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected_operators_forms: [],
};

const selectedOperatorsFormsSlice = createSlice({
  name: "selectedOperatorsForms",
  initialState,
  reducers: {
    setSelectedOperatorsForms: (state, action) => {
      state.selected_operators_forms = action.payload;
    },
  },
});

export default selectedOperatorsFormsSlice.reducer;
export const { setSelectedOperatorsForms } =
  selectedOperatorsFormsSlice.actions;
