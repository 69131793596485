import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total_payment_methods: 0,
};

const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    setTotalPaymentMethods: (state, action) => {
      state.total_payment_methods = action.payload;
    },
  },
});

export default paymentMethodsSlice.reducer;
export const { setTotalPaymentMethods } = paymentMethodsSlice.actions;
