

// ** Logo

import { useSkin } from "@hooks/useSkin";

const SpinnerComponent = () => {
  const { skin } = useSkin();

  return (
    <div className="fallback-spinner app-loader">
      <img
        className="fallback-logo"
        src={skin === "dark" ? "/assets/images/logo/logo.png" : "/assets/images/logo/logo.png"}
        alt="logo"
        width={80}
        height={80}
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
