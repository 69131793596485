import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: null,
    isLogin: false,
    token: null,
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload.userData
        ? action.payload.userData
        : state.userData;
      state.isLogin = action.payload.isLogin
        ? action.payload.isLogin
        : state.isLogin;
      state.token = action.payload.token ? action.payload.token : state.token;
    },
    handleLogout: (state) => {
      state.userData = null;
      state.isLogin = false;
      state.token = null;
      localStorage.clear();
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
