import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  one_time_payment_zone_information: {
    userID: null,
    userName: "",
    vehicleID: null,
    zoneID: null,
    seconds: null,
    paymentMethodID: "",
    couponCode: "",
    isCouponCodeApplied: false,
  },
  clientSecret: null,
  paymentIntentID: null,
  settedPaymentMethodID: "",
  parkingOperatorInfo: null,
};

const oneTimePaymentZoneInformationSlice = createSlice({
  name: "oneTimePaymentZoneInformation",
  initialState,
  reducers: {
    setOneTimePaymentZoneInformation: (state, action) => {
      state.one_time_payment_zone_information = action.payload;
    },
    setClientSecret: (state, action) => {
      state.clientSecret = action.payload;
    },
    setPaymentIntentID: (state, action) => {
      state.paymentIntentID = action.payload;
    },
    setPaymentMethodID: (state, action) => {
      state.settedPaymentMethodID = action.payload;
    },
    setParkingOperatorInfo: (state, action) => {
      state.parkingOperatorInfo = action.payload;
    },
  },
});

export default oneTimePaymentZoneInformationSlice.reducer;
export const {
  setOneTimePaymentZoneInformation,
  setClientSecret,
  setPaymentMethodID,
  setParkingOperatorInfo,
  setPaymentIntentID,
} = oneTimePaymentZoneInformationSlice.actions;
