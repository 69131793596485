import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "@store/store";
import { persistor } from "@store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "@src/utility/context/ThemeColors";
import "@src/configs/i18n";
import Spinner from "@components/spinner/Fallback-spinner";
import "@components/ripple-button";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@styles/react/libs/toastify/toastify.scss";
import "@src/@core/assets/fonts/feather/iconfont.css";
import "@src/@core/scss/core.scss";
import "@src/assets/scss/style.scss";
import "@src/index.scss";
import * as serviceWorker from "./serviceWorker";
import { TourProvider } from "@reactour/tour";
import { reactTourStepsForDriver } from "./constants";
import { setShowDriverTour } from "./redux/features/driverTour/driverTourSlice";

const LazyApp = lazy(() => import("./App"));

const reactTourStyles = {
  popover: (base) => ({
    ...base,
    //"--reactour-accent": "#ef5a3d",
    borderRadius: 10,
    marginLeft: 300,
    backgroundColor: "#fff",
    color: "#000",
  }),
  maskArea: (base) => ({ ...base, rx: 10 }),
  maskWrapper: (base) => ({ ...base }),
  badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
  controls: (base) => ({ ...base, marginTop: 100 }),
  close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <TourProvider
            steps={reactTourStepsForDriver}
            styles={reactTourStyles}
            beforeClose={() => {
              store.dispatch(setShowDriverTour(false));
            }}
          >
            <LazyApp />
          </TourProvider>
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
