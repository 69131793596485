import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { AllEnums } from "../../../@core/components/enums";

const initialState = {
  operator_driver_based_reports_result: [],
  operator_driver_based_reports_compare_result: [],
  show_driver_based_reports_compare_result: false,
  operator_time_based_reports_result: [],
  operator_time_based_reports_compare_result: [],
  show_time_based_reports_compare_result: false,
  operator_reports_loading: false,
  driver_reports_loading: false,
  driver_compare_reports_loading: false,
  operator_time_based_reports_filters: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: [],
    operators: null,
    operatorsList: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: [],
    compareOperators: null,
    comparisonMode: {
      value: AllEnums.ReportComparisonOn.Location,
      label: "Location",
    },
  },
  operator_driver_based_reports_filters: {
    showDefaultDateRange: true,
    defaultStartDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    defaultEndDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      value: 5,
      label: "Last Month",
    },
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    locations: [],
    lots: [],
    sections: [],
    groups: null,
    operators: null,
    operatorsList: [],
    isCompare: false,
    compareDateRange: null,
    compareStartDate: null,
    compareEndDate: null,
    compareLocations: [],
    compareLots: [],
    compareSections: [],
    compareGroups: null,
    compareOperators: null,
    comparisonMode: {
      value: AllEnums.ReportComparisonOn.Location,
      label: "Location",
    },
  },
};

const operatorReportsSlice = createSlice({
  name: "operatorReports",
  initialState,
  reducers: {
    setOperatorDriverBasedReportsResult: (state, action) => {
      state.operator_driver_based_reports_result = action.payload;
    },
    setOperatorDriverBasedReportsCompareResult: (state, action) => {
      state.operator_driver_based_reports_compare_result = action.payload;
    },
    setShowDriverBasedReportsCompareResult: (state, action) => {
      state.show_driver_based_reports_compare_result = action.payload;
    },
    setOperatorTimeBasedReportsResult: (state, action) => {
      state.operator_time_based_reports_result = action.payload;
    },
    setOperatorTimeBasedReportsCompareResult: (state, action) => {
      state.operator_time_based_reports_compare_result = action.payload;
    },
    setShowTimeBasedReportsCompareResult: (state, action) => {
      state.show_time_based_reports_compare_result = action.payload;
    },
    setOperatorReportsLoading: (state, action) => {
      state.operator_reports_loading = action.payload;
    },
    setDriverReportsLoading: (state, action) => {
      state.driver_reports_loading = action.payload;
    },
    setDriverCompareReportsLoading: (state, action) => {
      state.driver_compare_reports_loading = action.payload;
    },
    setOperatorTimeBasedReportsFilters: (state, action) => {
      state.operator_time_based_reports_filters = action.payload;
    },
    setOperatorDriverBasedReportsFilters: (state, action) => {
      state.operator_driver_based_reports_filters = action.payload;
    },
    setOperatorReportsToInitialState: (state, action) => {
      (state.operator_driver_based_reports_result =
        initialState.operator_driver_based_reports_result),
        (state.operator_driver_based_reports_compare_result =
          initialState.operator_driver_based_reports_compare_result),
        (state.show_driver_based_reports_compare_result =
          initialState.show_driver_based_reports_compare_result),
        (state.operator_time_based_reports_result =
          initialState.operator_time_based_reports_result),
        (state.operator_time_based_reports_compare_result =
          initialState.operator_time_based_reports_compare_result),
        (state.show_time_based_reports_compare_result =
          initialState.show_time_based_reports_compare_result),
        (state.operator_reports_loading =
          initialState.operator_reports_loading),
        (state.driver_reports_loading = initialState.driver_reports_loading),
        (state.driver_compare_reports_loading =
          initialState.driver_compare_reports_loading),
        (state.operator_time_based_reports_filters =
          initialState.operator_time_based_reports_filters),
        (state.operator_driver_based_reports_filters =
          initialState.operator_driver_based_reports_filters);
    },
  },
});

export default operatorReportsSlice.reducer;
export const {
  setOperatorDriverBasedReportsResult,
  setOperatorDriverBasedReportsCompareResult,
  setOperatorTimeBasedReportsResult,
  setOperatorTimeBasedReportsCompareResult,
  setOperatorReportsLoading,
  setOperatorReportsToInitialState,
  setShowTimeBasedReportsCompareResult,
  setShowDriverBasedReportsCompareResult,
  setDriverReportsLoading,
  setDriverCompareReportsLoading,
  setOperatorTimeBasedReportsFilters,
  setOperatorDriverBasedReportsFilters,
} = operatorReportsSlice.actions;
