import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wizard_stepper_for_one_time_payment: null,
};

const wizardStepperForOneTimePaymentSlice = createSlice({
  name: "wizardStepperForOneTimePayment",
  initialState,
  reducers: {
    setWizardStepperForOneTimePayment: (state, action) => {
      state.wizard_stepper_for_one_time_payment = action.payload;
    },
  },
});

export default wizardStepperForOneTimePaymentSlice.reducer;
export const { setWizardStepperForOneTimePayment } =
  wizardStepperForOneTimePaymentSlice.actions;
