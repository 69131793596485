import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wizard_stepper: null,
};

const wizardStepperSlice = createSlice({
  name: "wizardStepper",
  initialState,
  reducers: {
    setWizardStepper: (state, action) => {
      state.wizard_stepper = action.payload;
    },
  },
});

export default wizardStepperSlice.reducer;
export const { setWizardStepper } = wizardStepperSlice.actions;
