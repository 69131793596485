import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification_hub_connection: null,
};

const notificationHubConnectionSlice = createSlice({
  name: "notificationHubConnection",
  initialState,
  reducers: {
    setNotificationHubConnection: (state, action) => {
      state.notification_hub_connection = action.payload;
    },
  },
});

export default notificationHubConnectionSlice.reducer;
export const { setNotificationHubConnection } =
  notificationHubConnectionSlice.actions;
