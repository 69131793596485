// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    role: null,
  },
  reducers: {
    setRoles: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { setRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
