import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import roles from "./roles";
import wizardStepperReducer from "./features/wizardStepper/wizardStepperSlice";
import selectedPreferredOperatorsReducer from "./features/selectedPreferredOperators/selectedPreferredOperatorsSlice";
import selectedOperatorsFormsReducer from "./features/selectedOperatorsForms/selectedOperatorsFormsSlice";
import operatorsFormsResponsesReducer from "./features/operatorsFormsResponses/operatorsFormsResponsesSlice";
import orderFobReducer from "./features/orderFob/orderFobSlice";
import oneTimePaymentZoneInformationReducer from "./features/oneTimePaymentZoneInformation/oneTimePaymentZoneInformationSlice";
import operatorDashboardFiltersReducer from "./features/operatorDashboardFilters/operatorDashboardFiltersSlice";
import wizardStepperForOneTimePaymentReducer from "./features/wizardStepperForOneTimePayment/wizardStepperForOneTimePaymentSlice";
import paymentMethodsReducer from "./features/paymentMethods/paymentMethodsSlice";
import operatorReportsReducer from "./features/operatorReports/operatorReportsSlice";
import unreadNotificationsCountReducer from "./features/unreadNotificationsCount/unreadNotificationsCountSlice";
import stripeValidationsReducer from "./features/stripeValidations/stripeValidationsSlice";
import skinReducer from "./features/skin/skinSlice";
import menuCollapsedReducer from "./features/menuCollapsed/menuCollapsedSlice";
import notificationHubConnectionReducer from "./features/notificationHubConnection/notificationHubConnectionSlice";
import parkingHistoryFiltersReducer from "./features/parkingHistoryFilters/parkingHistoryFiltersSlice";
import activeSessionsFiltersReducer from "./features/activeSessionsFilters/activeSessionsFiltersSlice";
import driverTourReducer from "./features/driverTour/driverTourSlice";
import utilizationListFiltersReducer from "./features/utilizationListFilters/utilizationListFiltersSlice";
import roleModulesReducer from "./features/roleModules/roleModulesSlice";
import roleActionsReducer from "./features/roleActions/roleActionsSlice";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  roles,
  wizardStepper: wizardStepperReducer,
  selectedPreferredOperators: selectedPreferredOperatorsReducer,
  selectedOperatorsForms: selectedOperatorsFormsReducer,
  operatorsFormsResponses: operatorsFormsResponsesReducer,
  orderFob: orderFobReducer,
  oneTimePaymentZoneInformation: oneTimePaymentZoneInformationReducer,
  operatorDashboardFilters: operatorDashboardFiltersReducer,
  wizardStepperForOneTimePayment: wizardStepperForOneTimePaymentReducer,
  paymentMethods: paymentMethodsReducer,
  operatorReports: operatorReportsReducer,
  stripeValidations: stripeValidationsReducer,
  unreadNotificationsCount: unreadNotificationsCountReducer,
  skin: skinReducer,
  menuCollapsed: menuCollapsedReducer,
  notificationHubConnection: notificationHubConnectionReducer,
  parkingHistoryFilters: parkingHistoryFiltersReducer,
  activeSessionsFilters: activeSessionsFiltersReducer,
  driverTour: driverTourReducer,
  utilizationListFilters: utilizationListFiltersReducer,
  roleModules: roleModulesReducer,
  roleActions: roleActionsReducer,
});

export default rootReducer;
