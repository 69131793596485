export const AllEnums = {
  DenominationEnum: {
    Percentage: 1,
    Monetary: 2,
    ExactValue: 3,
    Seconds: 4,
    Minutes: 5,
    Hours: 6,
    Day: 7,
  },
  DeniminationEnum_Percentage: {
    Percentage: 1,
  },
  DeniminationEnum_Monetary: {
    Monetary: 2,
  },
  DeniminationEnum_ExactValue: {
    ExactValue: 3,
  },
  DeniminationEnum_Time: {
    Seconds: 4,
    Minutes: 5,
    Hours: 6,
    Day: 7,
  },
  MaxDiscountDenominationEnum: {
    Percentage: 1,
    Monetary: 2,
  },
  thenDenominationEnum: {
    Percentage: 1,
    Monetary: 2,
  },
  CurrencyTypeEnum: {
    None: 1,
    USD: 2,
  },
  ActionEnum: {
    TotalAmount: {
      title: "Total Amount",
      value: 1,
    },
    IncrementalRate: {
      title: "Incremental Rate",
      value: 2,
    },
  },
  ActionEnumForTotalAmount: {
    TotalAmount: {
      title: "Total Amount",
      value: 1,
    },
  },
  ListingType: {
    All: {
      title: "All",
      value: 1,
    },
    LocationsOnly: {
      title: "Locations Only",
      value: 2,
    },
    LotsOnly: {
      title: "Lots Only",
      value: 3,
    },
    SectionsOnly: {
      title: "Sections Only",
      value: 4,
    },
  },
  ActionType: {
    Increment: {
      title: "Increase",
      value: 1,
    },
    Decrement: {
      title: "Decrease",
      value: 2,
    },
    SetValueTo: {
      title: "Set Value To",
      value: 3,
    },
  },
  ComparisonEnum: {
    GreaterThan: {
      title: "Greater Than",
      value: 1,
    },
    EqualTo: {
      title: "Equal To",
      value: 2,
    },
    LessThan: {
      title: "Less Than",
      value: 3,
    },
    GreaterThanEqualTo: {
      title: "Greather Than Equal To",
      value: 4,
    },
    LessThanEqualTo: {
      title: "Less Than Equal To",
      value: 5,
    },
    NotEqualto: {
      title: "Not Equal To",
      value: 6,
    },
  },
  TriggerOnEnumAndPeriodType: {
    TotalAmount: {
      title: "Total Amount",
      value: 1,
    },
    Capacity_Utilization: {
      title: "Capacity/Utilization",
      value: 2,
    },
    NumberOfSessionsPerLocation: {
      title: "Number Of Sessions Per Location",
      value: 3,
    },
    NumberOfSessionsPerDriver: {
      title: "Number Of Sessions Per Driver",
      value: 4,
    },
    "Average Time to Park": {
      // entrance to parking
      title: "Average Time to Park",
      value: 5,
    },
    "Average Time to Exit": {
      title: "Average Time to Exit",
      value: 6,
    },
    "Average Time Parked": {
      title: "Average Time Parked",
      value: 7,
    },
    DateRange: {
      title: "Date Range",
      value: 9,
    },
    RecurringDaily: {
      title: "Recurring Daily",
      value: 10,
    },
    RecurringWeekly: {
      title: "Recurring Weekly",
      value: 11,
    },
    RecurringMonthly: {
      title: "Recurring Monthly",
      value: 12,
    },
    RecurringYearly: {
      title: "Recurring Yearly",
      value: 13,
    },
  },
  BackendPeriodType: {
    DateRange: {
      title: "Date Range",
      value: 1,
    },
    RecurringDaily: {
      title: "Recurring Daily",
      value: 2,
    },
    RecurringWeekly: {
      title: "Recurring Weekly",
      value: 3,
    },
    RecurringMonthly: {
      title: "Recurring Monthly",
      value: 4,
    },
    RecurringYearly: {
      title: "Recurring Yearly",
      value: 5,
    },
  },
  WeekDay: {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  },
  RoleID: {
    Admin: 1,
    Driver: 2,
    ParkingOperator: 3,
    Operator: 4,
    LocationAdmin: 5,
    Manager: 6,
    Enforcement: 7,
  },
  createForm: {
    preferenceLevel: {
      None: 1,
      ParentBased: 2,
      LocationBased: 3,
    },
    questionType: {
      RadioButton: 1,
      CheckBox: 2,
      DropDown: 3,
    },
  },
  fobsType: {
    All: 1,
    AttachedToVehicle: 2,
    UnUsedFobs: 3,
  },
  paymentMethod: {
    DebitCard: 1,
    Paypal: 2,
    ParkEasyWallet: 3,
  },
  filterBy: {
    None: 1,
    All: 2,
    Both: 3,
    StandardOnly: 4,
    VariableOnly: 5,
  },
  DriverSpecificRatePlan_Relation: {
    BelongsTo: {
      title: "Belongs To",
      value: 1,
    },
    DoesNotBelongTo: {
      title: "Does Not Belong To",
      value: 2,
    },
  },
  LocationEntityType: {
    None: 1,
    Location: 2,
    Lot: 3,
    Section: 4,
  },
  TransactionStatus: {
    Pending: 1,
    Success: 2,
    Failure: 3,
  },
  OperatorDashboardFiltersDateRangeOptions: [
    {
      value: 1,
      label: "All Time",
    },
    {
      value: 2,
      label: "Today",
    },
    {
      value: 3,
      label: "Yesterday",
    },
    {
      value: 4,
      label: "Last Week",
    },
    {
      value: 5,
      label: "Last Month",
    },
    {
      value: 6,
      label: "Last 3 Months",
    },
    {
      value: 7,
      label: "Custom Date Range",
    },
  ],
  OperatorDashboardFiltersDateRange: {
    AllTime: 1,
    Today: 2,
    Yesterday: 3,
    LastWeek: 4,
    LastMonth: 5,
    Last3Months: 6,
    CustomDateRange: 7,
  },
  OrderStatus: {
    Ordered: 1,
    PaymentInVerification: 2,
    PaymentApproved: 3,
    PaymentRejected: 4,
    PaymentDisputed: 5,
    Approved: 6,
    Shipped: 7,
    Delivered: 8,
  },
  ReportVehiclesType: {
    Both: 1,
    Active: 2,
    Suspended: 3,
  },
  CouponDuration: {
    ONCE: 1,
    REPEATING: 2,
    FOREVER: 3,
  },
  CurrencyType: {
    None: 1,
    USD: 2,
  },
  ruleType: {
    All: 1,
    RateSpecific: 2,
    DriverSpecific: 3,
  },
  ReportComparisonOn: {
    Location: 1,
    Lot: 2,
    Section: 3,
    DriverGroup: 5,
    Operator: 4,
  },
  ReportFiltersDateRangeOptions: [
    {
      value: 2,
      label: "Today",
    },
    {
      value: 3,
      label: "Yesterday",
    },
    {
      value: 4,
      label: "Last Week",
    },
    {
      value: 5,
      label: "Last Month",
    },
    {
      value: 6,
      label: "Last 3 Months",
    },
    {
      value: 7,
      label: "Custom Date Range",
    },
  ],
  ReportFiltersDateRange: {
    Today: 2,
    Yesterday: 3,
    LastWeek: 4,
    LastMonth: 5,
    Last3Months: 6,
    CustomDateRange: 7,
  },
  notificationType: {
    All: 1,
    Read: 2,
    UnRead: 3,
  },
  notificationToggleFor: {
    All: 1,
    InSystem: 2,
    Email: 3,
    Text: 4,
  },
  CommunicationPreference: {
    Email: 1,
    Text: 2,
    Both: 3,
  },
  UserStatus: {
    Unknown: 1,
    Active: 2,
    Deleted: 3,
    PendingOnBoard: 4,
  },
  BeaconType: {
    None: 1,
    Entrance: 2,
    Exit: 3,
    Both: 4,
  },
  FobStatus: {
    None: 1,
    Valid: 2,
    Suspended: 3,
  },
  PermitStatus: {
    None: 1,
    Valid: 2,
    InValid: 3,
    Expired: 4,
    Consumed: 5,
  },
  Gender: {
    Male: 1,
    Female: 2,
    PreferNotToAnswer: 3,
  },
  RoleActions: {
    READ: 1,
    UPDATE: 2,
    ADD: 3,
    DELETE: 4,
    DOWNLOAD_FILE: 5,
    VIEW_IN_MENU: 6,
    CANCEL: 7,
    CONFIRM: 8,
  },
};
